import React from 'react';

const Keywords = () => {
  const data = [
    'web design',
    'Android developer',
    'free hosting',
    'promotion',
    'seo',
    'software developer',
    'web development company near me',
    'web developer',
    'game developer',
    'website design',
    'promote',
    'infinityfree',
    'android app development',
    'seo services',
    'free web hosting',
    'sales promotion,web design company',
    'website design company',
    'front end developer',
    'seositecheckup',
    'web development company',
    'web page design',
    'backlinks',
    'seo company',
    'free website hosting',
    'seoptimer',
    'google seo',
    'custom software development company',
    'moz seo',
    'seo backlinks',
    'website design services',
    'majestic seo',
    'wordpress seo',
    'ecommerce development company',
    'free hosting with cpanel',
    'local seo services',
    '4ps marketing',
    'seo specialist',
    'seo ranking',
    'homepage design',
    ' custom software development company',
    'organic search',
    'on page optimization',
    'iwebchk',
    'white hat seo',
    'wordpress website development',
    'free web hosting and domain',
    'custom software',
    'linkcollider',
    'marketing promotion',
    'professional seo services',
    'seo content writing',
    'creative websites',
    'local search',
    'front end web developer',
    'website developer near me',
    'unity developer',
    'web app development',
    'ecommerce seo',
    'website optimization',
    'agile software',
    'mobile programming',
    'seo and sem',
    'free email hosting',
    'software development services',
    'web application development company',
    'scrapeboxsoftware development methodologies',
  ];
  return (
    <div className='keywords'>
      {data.map((d, i) => {
        return <h1 key={i}>{d}</h1>;
      })}
    </div>
  );
};

export default Keywords;

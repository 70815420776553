import React, { useContext, useEffect } from 'react';
import { useSpring, animated } from 'react-spring';
import logo from './../../assets/logo3.png';
import { AppContext } from '../../context/app-context';
import RequestFormDrawer from './request-form';
import './index.scss';
import { triggerEvent } from '../../utils';

const Navbar = () => {
  const { responsive, setRequestDrawer, setIsResponsive } =
    useContext(AppContext);

  useEffect(() => {
    const width = window.innerWidth;
    setIsResponsive('desktop');
    if (width <= 768) setIsResponsive('mobile');
  }, [responsive, setIsResponsive]);

  const animation = useSpring({
    to: { y: 0 },
    from: { y: -100 },
  });

  const handleRequestQuote = () => {
    setRequestDrawer(true);
    triggerEvent('Request a quote', 'User is requesting quote');
  };

  return (
    <animated.div style={animation} className='navbar'>
      <img
        src={logo}
        alt='logo'
        className='navbar-logo'
        onClick={() => {
          window.location.href = '/';
        }}
      />
      <div className='navbar-links'>
        <ul>
          <li className='navbar-links-request' onClick={handleRequestQuote}>
            Request a quote
          </li>
        </ul>
      </div>
      <RequestFormDrawer />
    </animated.div>
  );
};

export default Navbar;

import React, { useContext, useState } from 'react';
import { AppContext } from '../../context/app-context';
import { Drawer, Form, Input, Button, Select, message } from 'antd';
import Recaptcha from 'react-google-recaptcha';
import { triggerEvent } from '../../utils';
import axios from 'axios';

const RequestFormDrawer = () => {
  const { requestDrawer, setRequestDrawer, responsive } =
    useContext(AppContext);
  const [form] = Form.useForm();

  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [recaptcha, setRecaptcha] = useState(true);
  const [msg, setMsg] = useState(false);
  const isMobile = responsive === 'desktop' ? '400px' : '350px';

  const sendData = async (data) => {
    triggerEvent('Request Submitted', 'Submitted Forms');
    setIsButtonLoading(true);
    const response = await axios.post(
      'https://backend.illusionwebdevelopers.com/mail',
      {
        body: data,
      }
    );
    if (response.status === 200) {
      setIsButtonLoading(false);
      message.success('Request sent successfully');
      setRequestDrawer(false);
      form.resetFields();
    } else {
      setMsg(true);
      setIsButtonLoading(false);
      message.error('Failed to send the request');
    }
  };

  return (
    <Drawer
      shouldUpdate
      form={form}
      closable
      title='Request a quote'
      autoFocus
      visible={requestDrawer}
      onClose={() => setRequestDrawer(false)}
      width={isMobile}
    >
      <Form
        name='RequestAQuote'
        layout='vertical'
        onFinish={(values) => {
          sendData(values);
        }}
        onFinishFailed={() => {}}
        autoComplete='off'
      >
        <Form.Item
          label='Full Name'
          name='name'
          rules={[
            {
              required: true,
              message: 'Please input your Name!',
            },
          ]}
        >
          <Input
            style={{ borderRadius: '0.4rem', outline: 'none' }}
            size='large'
          />
        </Form.Item>
        <Form.Item
          label='Email'
          name='email'
          rules={[
            {
              required: true,
              message: 'Please enter a valid email address',
            },
            {
              type: 'email',
              message: 'Please enter a valid email address',
            },
          ]}
        >
          <Input
            style={{ borderRadius: '0.4rem', outline: 'none' }}
            size='large'
          />
        </Form.Item>
        <Form.Item
          label='Phone Number'
          name='phone'
          rules={[
            {
              required: true,
              message: 'Please enter a valid phone number',
            },
          ]}
        >
          <Input
            type='text'
            style={{ borderRadius: '0.4rem', outline: 'none' }}
            size='large'
          />
        </Form.Item>
        <Form.Item
          label='Query Type'
          name='query'
          rules={[
            {
              required: true,
              message: 'Please select a value',
            },
          ]}
        >
          <Select>
            <Select.Option value='Web Development'>
              Web Development
            </Select.Option>
            <Select.Option value='App Development'>
              App Development
            </Select.Option>
            <Select.Option value='Project Query'>Project Query</Select.Option>
            <Select.Option value='Others'>Others</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          style={{ width: '100%' }}
          label='Description'
          name='description'
          rules={[
            {
              required: true,
              message: 'Please enter a description',
            },
          ]}
        >
          <Input.TextArea
            style={{ borderRadius: '0.4rem', outline: 'none' }}
            size='large'
            rows={3}
          />
        </Form.Item>
        <Form.Item>
          <Recaptcha
            sitekey='6LeL7ckUAAAAAI4cpGK0_StXu55Ahe6xVX6uT4UW'
            onChange={() => setRecaptcha(false)}
          />
        </Form.Item>
        {msg && <p style={{ color: 'red' }}>Error! request not sent.</p>}
        <Form.Item>
          <Button
            type='primary'
            disabled={recaptcha}
            htmlType='submit'
            style={{ width: '100%' }}
            loading={isButtonLoading}
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default RequestFormDrawer;

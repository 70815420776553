import React, { useContext } from 'react';
import { AppContext } from '../../context/app-context';
import { triggerEvent } from '../../utils';
import RequestFormDrawer from '../navbar/request-form';
import './index.scss';

const Quote = () => {
  const { setRequestDrawer } = useContext(AppContext);

  const handleRequestQuote = () => {
    setRequestDrawer(true);
    triggerEvent('Request a quote', 'User is requesting quote');
  };

  return (
    <div className='services'>
      <div className='services-head'>
        <h1>Your one step away from your dream project</h1>
      </div>
      <div className='services-quote'>
        <div className='navbar-links'>
          <ul>
            <li className='navbar-links-request' onClick={handleRequestQuote}>
              Request a quote
            </li>
          </ul>
        </div>
        <RequestFormDrawer />
      </div>
    </div>
  );
};

export default Quote;

import React, { useContext } from 'react';
import { Row, Col, Card } from 'antd';
import { AppContext } from '../../context/app-context';
import goalHow from './../../assets/goal-how.jpg';
import plansHow from './../../assets/plans-how.jpg';
import implementingHow from './../../assets/implement-how.jpg';
import './index.scss';

const Goals = () => {
  const { responsive } = useContext(AppContext);

  const isMobile = responsive === 'desktop' ? 8 : 24;

  const data = [
    {
      img: goalHow,
      title: 'GOALS',
      description:
        'We understand our clients requirement and provide plans accordingly and make their goal as ours.',
    },
    {
      img: plansHow,
      title: 'PLANS',
      description:
        'We plan accordingly as per the needs of clients and adopt recent methodologies to implement your requiremnents',
    },
    {
      img: implementingHow,
      title: 'ACTIONS',
      description:
        'We start research and development with accordance of the project to provide 100% satisfaction by our service.',
    },
  ];

  return (
    <div className='services'>
      <div className='services-head'>
        <h1>How we do</h1>
        <p>A fresh approach to financial services for entreperneurs</p>
      </div>
      <Row>
        {data.map((items, i) => {
          return (
            <Col span={isMobile} key={i}>
              <Card
                hoverable
                style={{
                  margin: '1rem',
                  borderRadius: '0.4rem',
                }}
                cover={<img alt='example' src={items.img} />}
              >
                <Card.Meta
                  title={items.title}
                  description={items.description}
                />
              </Card>
            </Col>
          );
        })}
      </Row>
    </div>
  );
};

export default Goals;

import React from 'react';
import { Carousel } from 'antd';
import { animated, useSpring } from 'react-spring';
import './index.scss';

const CarouselComponent = () => {
  const style = useSpring({ to: { x: 0 }, from: { x: -1000 } });
  function onChange(a, b, c) {
    console.log(a, b, c);
  }

  return (
    <Carousel afterChange={onChange} autoplay>
      <div className='carousel-img carousel-img-one'>
        <div className='carousel-text'>
          <animated.h1 style={style}>
            GET YOUR BUSINESS A ONLINE PRESENCE
          </animated.h1>
          <animated.h2 style={style}>
            Reach Customers All OVer World.
          </animated.h2>
          <animated.h2 style={style}>Reach Upto 753.04 Crores.</animated.h2>
        </div>
      </div>
      <div className='carousel-img carousel-img-two'>
        <div className='carousel-text'>
          <h1>Best Software Development Agency</h1>
          <h2>We build advance softwares in accordance with</h2>
          <h2>recent technology stack.</h2>
        </div>
      </div>
    </Carousel>
  );
};

export default CarouselComponent;

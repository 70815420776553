import React, { useContext } from 'react';
import { Row, Col } from 'antd';
import { FaFacebook, FaInstagram, FaYoutube } from 'react-icons/fa';
import { AppContext } from './../../context/app-context';
import { triggerEvent } from './../../utils';
import './index.scss';

const Footer = () => {
  const { responsive } = useContext(AppContext);

  const isMobile = responsive === 'desktop' ? 12 : 24;

  const navigateSocialIcons = (icon) => {
    if (icon === 'facebook') {
      triggerEvent('Social Icon', `cliked on ${icon}`);
      window.open('https://www.facebook.com/illusionwebdevelopers', '_blank');
    }
    if (icon === 'instagram') {
      triggerEvent('Social Icon', `cliked on ${icon}`);
      window.open('https://www.instagram.com/illusionweb/', '_blank');
    }
    if (icon === 'youtube') {
      triggerEvent('Social Icon', `cliked on ${icon}`);
      window.open(
        'https://www.youtube.com/channel/UCk6CxBtpyEY_EzJ44C940NA',
        '_blank'
      );
    }
  };

  const handleNavigation = (data) => {
    if (data === 'number') {
      triggerEvent('Contact', `User tried to call`);
      window.location.assign(`tel:+919739732124`);
    }
    if (data === 'email') {
      triggerEvent('Contact', `User tried to email`);
      window.location.assign(`mailto:support@illusionwebdevelopers.com`);
    }
  };

  return (
    <div className='footer'>
      <Row>
        <Col span={isMobile}>
          <h1>Services</h1>
          <ul>
            <li>Web Design</li>
            <li>Web Development</li>
            <li>Graphics Design</li>
            <li>Mobile Development</li>
            <li>Software Development</li>
            <li>Games Development</li>
            <li>SEO</li>
            <li>Promotion</li>
          </ul>
        </Col>
        <Col span={isMobile}>
          <h1>Contact us</h1>
          <ul>
            <li>Shed No.8 MTB Industrial Estate, 2nd Main Rd,</li>
            <li>near Ebenezer Gasesand Application, RHB Colony,</li>
            <li>Mahadevapura, Bengaluru, Karnataka - 560048</li>
            <li className='pointer' onClick={() => handleNavigation('number')}>
              +91-9739732124
            </li>
            <li className='pointer' onClick={() => handleNavigation('email')}>
              support@illusionwebdevelopers.com
            </li>
            <div>
              <FaFacebook
                size='20'
                className='footer-icons'
                onClick={() => navigateSocialIcons('facebook')}
              />
              <FaInstagram
                size='20'
                className='footer-icons'
                onClick={() => navigateSocialIcons('instagram')}
              />
              <FaYoutube
                size='20'
                className='footer-icons'
                onClick={() => navigateSocialIcons('youtube')}
              />
            </div>
          </ul>
        </Col>
      </Row>
      <Row>
        <Col span='24'>
          <div className='Copyright'>
            <p>
              Copyright © 2021. All rights reserved by Illusion Web Developers
            </p>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Footer;

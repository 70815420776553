import React, { createContext, useState, useEffect } from 'react';

export const AppContext = createContext({});

const AppContextProvider = ({ children }) => {
  const [requestDrawer, setRequestDrawer] = useState(false);
  const [responsive, setIsResponsive] = useState('desktop');

  useEffect(() => {
    const listner = window.addEventListener('resize', (e) => {
      if (e.target.innerWidth <= 768) {
        return setIsResponsive('mobile');
      }
      return setIsResponsive('desktop');
    });
    return () => {
      window.removeEventListener(listner);
    };
  }, []);

  const contextValues = {
    requestDrawer,
    setRequestDrawer,
    responsive,
    setIsResponsive,
  };

  return (
    <AppContext.Provider value={contextValues}>{children}</AppContext.Provider>
  );
};

export default AppContextProvider;

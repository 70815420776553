import React, { useEffect } from 'react';
import GoogleAnalytics from 'react-ga';
import Navbar from './components/navbar';
import AppContextProvider from './context/app-context';
import CarouselComponent from './components/carousel';
import About from './components/content/about';
import Footer from './components/footer';
import Services from './components/content/services';
import Goals from './components/content/goals';
import Keywords from './components/content/keywords';
import Quote from './components/content/quote';

const App = () => {
  useEffect(() => {
    GoogleAnalytics.initialize('UA-139265516-1');
    GoogleAnalytics.pageview('/');
  }, []);

  return (
    <AppContextProvider>
      <Navbar />
      <CarouselComponent />
      <About />
      <Services />
      <Goals />
      <Keywords />
      <Quote />
      <Footer />
    </AppContextProvider>
  );
};

export default App;

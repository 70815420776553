import React, { useContext } from 'react';
import { Row, Col } from 'antd';
import playbtnbg from './../../assets/playbtnbg.png';
import { AppContext } from '../../context/app-context';
import './index.scss';

const About = () => {
  const { responsive } = useContext(AppContext);
  const isMobile = responsive === 'desktop' ? 12 : 24;

  return (
    <div className='about'>
      <Row>
        <Col span={isMobile}>
          <h1>About Us</h1>
          <p>
            Illusion Web Developers is one of the best development agency. We
            develop all kinds of web, mobile and software based applications,
            and also we are expertise in graphics designing, SEO and Promotion.
            We provide our service in a cost efficent manner. we provide web
            development services like ECommerce platform, Booking Platform, API
            design and integration, CMS Platform, Travel Website, App
            Development, Desktop Application etc. We provide all our services
            using recent technology stack like React Js, Svelte, Node JS,
            Python, PHP, React Native, Flutter and Dart etc. Illusion web
            developers main motive is to provide our client a 100% satisfaction
            through our service. We have team who can deliver project with high
            quality and fully functional softwares with in no time.
          </p>
        </Col>
        <Col span={isMobile}>
          <img src={playbtnbg} alt='about-img' className='about-img' />
        </Col>
      </Row>
    </div>
  );
};

export default About;

import React, { useContext } from 'react';
import { Row, Col, Card } from 'antd';
import { AppContext } from './../../context/app-context';
import webdesign from './../../assets/webdesign-service.png';
import webdevelopment from './../../assets/webdevelopment-service.png';
import appdevelopment from './../../assets/app-services.jpg';
import software from './../../assets/software-service.jpg';
import game from './../../assets/game-service.jpg';
import graphics from './../../assets/graphics-service.jpg';
import seo from './../../assets/seo-service.jpg';
import marketing from './../../assets/marketing-services.jpg';
import './index.scss';

const Services = () => {
  const { responsive } = useContext(AppContext);

  const isMobile = responsive === 'desktop' ? 8 : 24;

  const data = [
    {
      img: webdesign,
      title: 'WEB DESIGN',
      description:
        'We provide one of the best website UI / UX for our customer. We use modern techniques to make a perfect design',
    },
    {
      img: webdevelopment,
      title: 'WEB DEVELOPMENT',
      description:
        'We Develop all types of website ex : E-Commerce, Social, Video CMS and More',
    },
    {
      img: graphics,
      title: 'GRAPHIC DESIGN',
      description:
        'We have professional AI illustrator and Corel draw Designer.Through this we provide our customer 100% satisfaction.',
    },
    {
      img: appdevelopment,
      title: 'MOBILE APPS',
      description:
        'We develop android app based on Javascript, Dart and Java Programming Language.',
    },
    {
      img: software,
      title: 'SOFTWARE DEVELOPMENT',
      description:
        'We develop android app based on Dart and Dot net Programming Language.',
    },
    {
      img: game,
      title: 'GAME DEVELOPMENT',
      description: 'We Develop 2d games for Mobile and Desktop.',
    },
    {
      img: seo,
      title: 'SEO',
      description:
        'We provide best SEO service to our customer to reach Google top rank, Offpage and Onpage SEO services provided.',
    },
    {
      img: marketing,
      title: 'PROMOTION',
      description: 'We provide all marketing services ex: Online and Offline.',
    },
  ];
  return (
    <div className='services'>
      <div className='services-head'>
        <h1>Services</h1>
        <p>We work with you to achieve your goals</p>
      </div>
      <Row>
        {data.map((items, i) => {
          return (
            <Col span={isMobile} key={i}>
              <Card
                hoverable
                style={{
                  margin: '1rem',
                  borderRadius: '0.4rem',
                }}
                cover={<img alt='example' src={items.img} />}
              >
                <Card.Meta
                  title={items.title}
                  description={items.description}
                />
              </Card>
            </Col>
          );
        })}
      </Row>
    </div>
  );
};

export default Services;
